import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <>
        <footer class="footer container-fluid btop">
          <div class="row footer-content">
            <div class="col-12 text-center mb-2">
              <h2>
                Maxversal Medicare<span class="dot ">.</span>
              </h2>
            </div>
            <div class="col-12 col-sm-4 pb-1">
              <div class="footer-widget">
                <h3>About us</h3>
                <p>
                  Maxversal Medicare is an organisation with deals with
                  nutraceutical products and fosters into multidimensional
                  treatment approach to maximise patient care.
                </p>
              </div>
            </div>
            <div class="col-7 col-sm-4 pb-4">
              <div class="footer-widget ">
                <h3>Office Address</h3>
                <div class="footer-widget-content">
                  <p class="mb-0">
                    Office No. S.21,22 <br />
                    Kris Korner Commercial Plaza
                    <br />
                    Opp. Saraswati Vidyalaya, Anand Nagar Ghodbandar Road, Thane
                    400615
                  </p>
                  <p class="mb-0">
                    <strong>Abhiroop Mukherjee</strong>
                  </p>
                  <a
                    href="mailto:abhirup.mukharjee@maxversalmedicare.com"
                    class="contact-link red"
                  >
                    abhirup.mukharjee@maxversalmedicare.com
                  </a>

                  <a href="tel:08376865000" class="contact-link">
                    +918910012391
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 text-center col-md-4">
              <div class="footer-widget gymhour">
                <h3>Office Hours</h3>
                <div class="footer-widget-content">
                  <div class="open-times">
                    <ul class="opening-time p-2">
                      <li>
                        <span>
                          <i class="fa fa-check"></i>
                        </span>
                        <p>
                          <strong>Mon - Fri:</strong> 10am - 6pm
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="footer-nav my-auto">
                <div className="socialBlock">
                  <h3> Follow Us </h3>
                  {/* <!-- <h3 class="fs-subtitle">on social media to increase your Impact.</h3> --> */}
                  <div>
                    <div className="facebook">
                      <a href="https://www.facebook.com/Lebenswerk-Consulting-106740008008819/">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="instagram">
                      <a href="https://www.instagram.com/consultinglebenswerk/">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="twitter">
                      <a href="https://www.lebenswerk.co.in">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 text-center copyright">
              <h6 class="contact-link red">
                © Copyright <span id="year"></span>Maxversal Medicare.
              </h6>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
export default Footer;
